<script setup>
const { breakpoint } = useBreakpoints();
defineProps(['year','opened'])

const isOpened = ref(() => breakpoint.value !== 'sm' || process.server);

</script>

<template>
<div class="border-b border-gray-400 sm:border-0 py-4">
  <div class="flex items-center cursor-pointer select-none" @click="isOpened = !isOpened">
    <div class="grow text-base pl-4 sm:pl-0 sm:text-2xl sm:border-b sm:border-gray-400 sm:pb-4">{{ year }}</div>
    <div class="px-4 sm:hidden">
      <svg v-if="isOpened" width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1H1" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg v-else width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1V7M7 7V13M7 7H13M7 7H1" stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
  <div v-show="isOpened" class="pt-4">
    <slot />
  </div>
</div>
</template>

<script setup>
const props = defineProps(['entry'])

const entryDate = computed(() => {
    return dateToTextPL(props.entry.date) + ", " + props.entry.date.substring(11,16)
})

</script>

<template>
  <div>
    <div class="flex gap-6">
      <div class="text-vehis-red">{{ entryDate }}</div>
      <div><a :href="entry.file" class="text-azure">Pobierz plik</a></div>
    </div>
    <div class="font-light pt-4">
      {{ entry.communicate }}
    </div>
  </div>
</template>

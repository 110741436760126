<script setup>
const backend = useBackend();
const {data: messages } = useAsyncData(() => backend.getCommunicates());

</script>

<template>
  <Container>
    <h1 class="text-3xl text-center sm:text-left sm:text-5xl text-vehis-red">RELACJE INWESTORSKIE</h1>
    <div class="text-sm pt-4 font-extralight">
      Szacunkowa (mogąca ulec zmianie) wartość zobowiązań finansowych (wycenionych zgodnie przepisami o&nbsp;rachunkowości) oraz szacowana struktura finansowania Emitenta rozumiana jako wartość i&nbsp;udział procentowy zobowiązań z&nbsp;tytułu pożyczek, emisji dłużnych papierów wartościowych, sekurytyzacji oraz faktoringu w&nbsp;sumie pasywów bilansu Emitenta, wyniesie na dzień 31.12.2024 odpowiednio nie więcej niż&nbsp;1,438&nbsp;mld&nbsp;zł, nie więcej niż 89,00&nbsp;% wartości pasywów, w&nbsp;sprawozdaniu jednostkowym VEHIS&nbsp;Finanse sp.&nbsp;z&nbsp;o.o.
    </div>
    <StaticSeparator />

    <div class="sm:pt-8">
      <div v-for="year in Object.keys(messages).sort().reverse()">
        <StaticCommRow :year="year">
          <div class="grid px-8 sm:px-0 grid-cols-1 sm:grid-cols-3 gap-12">
            <StaticCommItem v-for="entry in messages[year]"
                            :entry="entry" />
          </div>
        </StaticCommRow>
      </div>
    </div>

  </Container>
</template>
